<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-server menu="promotion"></submenu-server>

      <div class="submenu-content">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- show page content -->
        <div v-if="item">
          <!-- page title-->
          <h2>{{item.intro}}</h2>
          <br/>

          <div v-for="b in item.body">

            <div v-if="b.type === 'heading'">              
              <div v-if="b.value === 'Inquiry Button'">
                <router-link class="btn btn-primary btn-lg" to="/contact-us/server">Inquiry/Setup Account</router-link>
                <br/><br/>
              </div>
              <div v-else>
                <h4>{{b.value}}</h4>
              </div>
            </div>

            <div v-else-if="b.type === 'paragraph'" class="paragraph">
              <p v-html="b.value"></p>
            </div>

            <!-- special offer image 
            ( click this image then the data table will show up , click again the table will be hidden )-->
            <div v-else-if="b.type === 'image'">
              <img
              :src="b.value.large.src"
              :width="b.value.large.width"
              :height="b.value.large.height" class="pointer" @click="handleOpenClose">
            </div>

            <!-- stream block:
                  three blockes that is right under the special offer image
            -->
            <div v-else-if="b.type === 'stream_block'"> 
              <div v-for="sb in b.value">
                
                <div class="float-start block">
                  <div v-for="(itm, index) in sb.value.image_stream">
                    <img :src="itm.value.medium.src"
                    :width="itm.value.medium.width"
                    :height="itm.value.medium.height">    
                  </div>
                  <div v-if="sb.value.heading" class="heading">{{sb.value.heading}}</div>
                  <div v-if="sb.value.paragraph" v-html="sb.value.paragraph"></div>
                  <div class="clear"></div>
                </div>
                
              </div>
              <div class="clear"></div>
              <br/><br/><br/>
            </div>
            <!-- end of stream block-->
            
            <!-- special offer table block 
                It will only show up when you click the image
            -->
            <div v-else-if="b.type === 'table'" :class="{hide:!show}">
              <div class="table_block"> 
                <!-- table title and logo -->
                <div v-if="b.value.data.table_caption">
                  <h4>{{b.value.data.table_caption}}
                  <img :src="b.value.table_header_column[0].value.image.medium.src"
                      width="87"> </h4>
                </div>

                <!-- two images under the table title-->
                <div class="table_head_image">
                  <div v-for="(itm, index) in b.value.table_header_column">
                    <div v-if="index > 0" class="float-start">
                      <img :src="itm.value.image.medium.src"
                        :width="itm.value.image.medium.width"
                        :height="itm.value.image.medium.height" class="image_pad">  
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>

                <!-- table data -->
                <div class="table_data">
                  <table class="table table-sm">
                    <tr v-for="(row, i) in b.value.data.data">
                      <td v-for="(column, j) in row" v-if="i===0" class="td_top">
                        <div v-if="j===0"><span class="gray">{{column}}</span></div>
                        <div v-else-if="j===1"><span class="red">{{column}}</span></div>
                        <div v-else-if="j===2"><span class="blue">{{column}}</span></div>
                      </td>
                      <td v-for="(column, j) in row" v-else>
                        <div v-if="i===1"><strong>{{column}}</strong></div>
                        <div v-else>{{column}}</div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>  
            <!-- end of special offer table block  -->
          
          </div>
        
        <!-- end of page content -->
      </div>

  </div>
  </div>
</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'ServerPromotions',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})
    const show = ref(false)
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Server Solutions", link: "ServerSolutions"})
    breadcrumb_links.value.push({text: "Server Promotions", link: "ServerPromotions"})

    getAPI
    .get('/content/pages/53')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    const handleOpenClose = () => {
      if (show.value == true) {
        show.value = false
      }
      else {
        show.value = true
      }
    }

    return { error, item, show, handleOpenClose, breadcrumb_links}
  }
}
</script>

<style scoped>
.clear {clear:both;}
.pad {margin-right:35px;}
.pointer {cursor: pointer;}
.block {width:30%; margin-top:50px;}
.block .heading {border-bottom: 1px solid #333; font-size:25px; }
.hide {display:none;transition: opacity 1s ease-out;
    opacity: 0}
.table_block {margin-top:50px;}
.table_head_image {padding-left: 100px;}
.image_pad {margin-left:50px;}
.table_data td {border-bottom: 1px solid #ccc}
.table_data .td_top {border-bottom: 0px solid #ccc; font-size:20px;}
.gray {color:#888; font-weight:bold}
.red {color:red; }
.blue {color:blue;}
</style>